<template>
    <a-card title="组态监测" :body-style="{padding: '5px 5px'}">
        <div style="height: calc(100vh - 210px);">
            <iframe :src="'/zwvr/index.html?id=' + 6" width="100%" height="100%;" :frameborder="0"/>
        </div>
    </a-card>
</template>

<script>
  export default {
    name: "zutai"
  };
</script>

<style scoped>

</style>
